<template>
    <div class="content-wrapper cv-section">
        <h1>{{ $t('Edit Languages') }}</h1>

        <form @submit.prevent="save">
            <template v-for="(canLang, index) in items" :key="index">
                <div :class="['labeled-icon-input', {'has-error': errors[`languages.${index}.id`]}]">
                    <div class="form-group">
                        <label>{{ $t('Language') }}</label>
                        <Multiselect
                            v-model="items[index].id"
                            :options="languages"
                            :placeholder="$t('Select language')"
                            class="form-control"
                            :searchable="true"
                        />
                    </div>
                    
                    <FormErrors v-if="errors[`languages.${index}.id`]" :errors="errors[`languages.${index}.id`]" />

                </div>

                <div :class="['labeled-icon-input', {'has-error': errors[`languages.${index}.level`]}]">
                    <div class="form-group">
                        <label :for="`level_${index}`">{{ $t('Level') }}</label>
                        <Multiselect
                            v-model="items[index].level"
                            :options="levels"
                            :placeholder="$t('Select level')"
                            class="form-control"
                        />
                    </div>
                    
                    <FormErrors v-if="errors[`languages.${index}.level`]" :errors="errors[`languages.${index}.level`]" />

                </div>

                <button type="button" class="btn btn-grey" @click="deleteItem(index)">
                    {{ $t('Remove') }}
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.764" viewBox="0 0 15 17.764">
                        <g>
                            <path d="M8.591 17.728a2.419 2.419 0 0 0 2.419 2.317h7.38a2.419 2.419 0 0 0 2.419-2.317l.475-10.939H8.116zm7.9-8.6a.605.605 0 0 1 1.21.039l-.279 7.933a.6.6 0 0 1-.6.584.6.6 0 0 1-.6-.626zm-4.207-.581a.6.6 0 0 1 .626.587l.281 7.926a.6.6 0 0 1-.6.626.6.6 0 0 1-.6-.584l-.266-7.935a.6.6 0 0 1 .569-.62zM21.6 4.37h-4.036V2.885a.6.6 0 0 0-.6-.6h-4.523a.6.6 0 0 0-.6.6V4.37H7.8a.6.6 0 0 0 0 1.21h13.8a.6.6 0 1 0 0-1.21zm-8.549-.88h3.308v.88h-3.313z" transform="translate(-7.2 -2.28)"/>
                        </g>
                    </svg>
                </button>
            </template>
            <button @click="add" type="button" class="btn btn-transparent-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                    <path d="M13 0a13 13 0 1 0 13 13A13 13 0 0 0 13 0zm6.006 17l-2 2-4-4-4 4-2-2 4-4-4-4 2-2 4 4 4-4 2 2-4 4z"/>
                </svg>
                {{ $t('Add more') }}
            </button>
            <button class="btn btn-primary" type="submit">{{ $t('Save') }}</button>
        </form>
    </div>
</template>

<script>
import languageLevels from "@/js/languageLevels";
import { cvService } from "@/services/cvService";
import CvSectionMixin from "@/mixins/CvSection";
import Multiselect from '@vueform/multiselect'
import FormErrors from "@/components/widgets/FormErrors";

export default {
    name: "EditLanguages",
    mixins: [ CvSectionMixin ],
    components: {
        Multiselect,
        FormErrors
    },
    data() {
        return {
            levels: languageLevels,
            updateService: cvService.updateLanguages,
            getService: cvService.languages,
            items: [
                {
                    id: '',
                    level: ''
                }
            ],
        }
    },
    methods: {
        getNewRecords() {
            return {
                'languages': this.items
            }
        },
        getSingleItem() {
            return {
                id: '',
                level: ''
            }
        }
    },
    computed: {
        languages() {
            return this.$store.getters.languages;
        }
    }
}
</script>